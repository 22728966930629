import {Cred, fromCred, mapCred} from "../cred/Cred.ts";
import {Login} from "../login/Login.ts";
import {Register} from "../register/Register.ts";

const API_URL: string = '/api/cred';
const CRED_ALL_MAP: string = '/all';
export const AUTH_URL: string = '/auth/token';
export const LOGOUT_URL: string = '/exit';
export const SIGN_UP_URL: string = '/sign-up';
export const LOGIN_URL: string = '/login';
export const RECOVERY_URL: string = '/recovery';
export const REGISTER_URL: string = '/register';
export const MAIN_PAGE_URL: string = '/';

export const getAllCreds = async (
    key: string,
    siteName: string = '',
    page: number = 0,
    size: number = 10
): Promise<{ totalPages: number; totalElements: number; creds: Cred[] }> => {
    try {
        const url = `${API_URL}${CRED_ALL_MAP}?page=${page}&size=${size}${siteName ? `&site_name=${siteName}` : ''}`;
        const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(key)
            }
        );

        if (response.status == 403) {
            window.location.href = LOGIN_URL;
        }

        const { totalPages, totalElements, result } = await response.json();
        return {
            totalPages,
            totalElements,
            creds: result.map(mapCred),
        };
    } catch (error) {
        console.error('Error fetching credentials:', error);
        return { totalPages: 0, totalElements: 0, creds: [] };
    }
};

export const removeCred = async (id: string): Promise<Response> => {
    return await fetch(`${API_URL}/${id}`, { method: 'DELETE' }).then(response => response);
}

export const addCred = async (cred: Cred, key: string): Promise<Cred> => {
    const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fromCred(cred, key)),
    });
    if (response.status == 403) {
        window.location.href = LOGIN_URL;
    }
    if (!response.ok) {
        throw new Error('Failed to add credential');
    }

    return mapCred(await response.json());
}

export const editCred = async (cred: Cred, key: string): Promise<Cred> => {
    const response = await fetch(`${API_URL}/${cred.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fromCred(cred, key)),
    });
    if (response.status == 403) {
        window.location.href = LOGIN_URL;
    }
    if (!response.ok) {
        throw new Error('Failed to edit credential');
    }
    return mapCred(await response.json());
}

export const authRequest = async (login: string, password: string) => {
    const loginRequest: Login = { login, password };
    return fetch(AUTH_URL, {

        method: 'POST',
        headers: {
            'Content-Type': 'application/json',

        },
        body: JSON.stringify(loginRequest),
    })
}

export const checkAuth = async (): Promise<boolean> => {
    return fetch(AUTH_URL, {
        method: 'GET',
    }).then(response => {
        return response.ok;
    })
    .catch(() => false);
}

export const registerRequest = async (login: string, password: string, email: string) => {
    const registerRequest: Register = { login, password, email };
    return fetch(SIGN_UP_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(registerRequest),
    })
}

export const logoutRequest = async () => {
    return fetch(LOGOUT_URL, {
        method: 'DELETE',
    })
}