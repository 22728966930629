import React from "react";


interface TodoInputProps {
    filterCred: (newTodo: string) => void;
}

export default function CredFilter ({ filterCred }: TodoInputProps) {
    const [siteName, setSiteName] = React.useState('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        filterCred(siteName);
    };


    return (
        <form onSubmit={handleSubmit} className="credFilter">
            <input
                type="text"
                value={siteName}
                onChange={(event) => setSiteName(event.target.value)}
                placeholder="Введите имя сайта"
            />
            <button type="submit">Поиск</button>
        </form>
    )
}