import CredCard from "./CredCard.tsx";
import {Cred} from "./Cred.ts";

interface TodoListProps {
    creds: Cred[];
    onDeleteCred: (index: string) => void;
    onEditCred: (newCred: Cred) => void;
}

export default function CredList({creds, onDeleteCred, onEditCred} : TodoListProps) {
    return (
        <div className='credList'>
            <ul>
                {creds.map((cred) => (
                    <CredCard key={cred.toString()} cred={cred} onDeleteCred={onDeleteCred} onEditCred={onEditCred}/>
                ))}
            </ul>
        </div>
    );
}