import {getAnotherTheme} from './ThemeConst.ts';

interface ThemeComponentProps {
    theme: string;
    setTheme: (theme: string) => void;
}

export default function ThemeComponent({theme, setTheme}: ThemeComponentProps) {

    return (
        <div className="theme">
            <button onClick={
                () => {
                    const newTheme = getAnotherTheme(theme);
                    localStorage.setItem("theme", newTheme);
                    setTheme(newTheme)
                }
            }>
                <i className="fa-solid fa-circle-half-stroke"></i>
            </button>
        </div>

    )
}