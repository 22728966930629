import {Cred} from "./Cred.ts";
import {useState} from "react";
import {CopyTextComponent} from "../CopyTextComponent.tsx";

interface TodoProps {
    cred: Cred;
    onDeleteCred: (index: string) => void;
    onEditCred: (newCred: Cred) => void;
}


export default function CredCard({cred, onDeleteCred, onEditCred}: TodoProps) {
    const [siteName, setSiteName] = useState(cred.siteName);
    const [username, setUsername] = useState(cred.username);
    const [password, setPassword] = useState(cred.password);
    const [editing, setEditing] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleSiteNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSiteName(event.target.value);
    };

    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };
    return (
        <li className='credItem'>

            {editing ? (
                <input type="text" value={siteName} onChange={handleSiteNameChange}/>
            ) : (
                <CopyTextComponent text={siteName}/>
            )}

            {editing ? (
                <input type="text" value={username} onChange={handleUsernameChange}/>
            ) : (
                <CopyTextComponent text={username}/>
            )}

            {editing ? (
                <input type="text" value={password} onChange={handlePasswordChange}/>
            ) : (
                <CopyTextComponent text={password} showText={showPassword}/>
            )}

            <div className='actionsContainer'>
                <button onClick={() => {
                    setShowPassword(!showPassword)
                }}>
                    <i className="fa-solid fa-eye"></i>
                </button>
                <button onClick={() => {
                    if (editing) {
                        if (confirm('Вы уверены что хотите изменить запись?')) {
                            const editedCred = new Cred(cred.id, siteName, username, password);
                            onEditCred(editedCred);
                        }
                        setEditing(false);
                    } else {
                        setEditing(true);
                    }
                }}>
                    <i className="fa-solid fa-pen-to-square"></i>
                </button>
                <button onClick={() => {
                    if (confirm('Вы уверены что хотите удалить запись?')) {
                        onDeleteCred(cred.id)
                    }
                }}>
                    <i className="fa-regular fa-trash-can"></i>
                </button>
            </div>
        </li>
    )
}