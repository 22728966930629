import {Navigate} from 'react-router-dom';
import {LOGIN_URL} from "../request/RequestSender.ts";
import {useAuth} from "../auth/AuthContext.tsx";

const ProtectedRoute = ({children, }: { children: JSX.Element}) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Loading...</div>;
    }
    return isAuthenticated ? children : <Navigate to={LOGIN_URL} />;
};

export default ProtectedRoute;