import React, {useState} from "react";
import {LOGIN_URL, registerRequest} from "../request/RequestSender.ts";
import './RegisterComponent.css';
import {LoginButtonComponent} from "../auth/LoginButtonComponent.tsx";
import {useNavigate} from "react-router-dom";

export default function RegisterComponent() {
    const [login, setLogin] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const validateForm = () => {
        const errors: { login: string; password: string } = {
            login: '',
            password: '',
        };

        if (login.trim() === '') {
            errors.login = 'Логин это обязательное поле';
        }

        if (password.trim() === '') {
            errors.password = 'Пароль это обязательное поле';
        }

        return errors;
    }

    const navigate = useNavigate();
    const loginNavigate = () => navigate(LOGIN_URL);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const errors = validateForm();
        if (errors.login !== '' || errors.password !== '') {
            setError(errors.login || errors.password);
            return;
        }
        registerRequest(login, password, email)
            .then(response => {
                if (response.status !== 201) {
                    throw new Error('Failed to register');
                }
            })
            .catch(error => setError(error.message));
        if (!error) {
            loginNavigate();
        }
    }

    return (
        <>
            <LoginButtonComponent/>
            <form onSubmit={handleSubmit} className='register-form'>
                <div className='register-form-wrapper'>
                    <input
                        type="text"
                        placeholder="Логин"
                        value={login}
                        onChange={(event) => setLogin(event.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <input
                        type="text"
                        placeholder="Почта"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    <button type="submit"><label>Регистрация</label></button>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                </div>
            </form>
        </>
    )
}