import React, {useState} from 'react';
import {useAuth} from "../auth/AuthContext.tsx";
import './LoginComponent.css';
import {RecoveryButtonComponent} from "../auth/RecoveryButtonComponent.tsx";
import {REGISTER_URL} from "../request/RequestSender.ts";


const LoginForm = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const { auth } = useAuth();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        auth(login, password)
            .catch((error) => setError(error.message));
    };

    return (
        <>
            <RecoveryButtonComponent/>
            <div className='login-form-wrapper'>
                <form onSubmit={handleSubmit} className='login-form'>
                    <input
                        type="text"
                        placeholder="Логин"
                        value={login}
                        onChange={(event) => setLogin(event.target.value)}/>
                    <input
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}/>
                    <button type="submit"><label>Войти</label></button>
                    {error && <p style={{color: 'red'}}>{error}</p>}
                </form>
                <a href={REGISTER_URL}>
                    <button className="register-button"><label>Регистрация</label></button>
                </a>
            </div>
        </>
    );
};

export default LoginForm;