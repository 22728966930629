import {useState} from "react";
import {THEME_DARK} from "./components/theme/ThemeConst.ts";
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes} from "react-router-dom";
import LoginComponent from "./components/login/LoginComponent.tsx";
import CredComponent from "./components/cred/CredComponent.tsx";
import {ThemeContext} from "./components/theme/ThemeContext.tsx";
import ThemeComponent from "./components/theme/ThemeComponent.tsx";
import NoPage from "./components/NoPage.tsx";
import ProtectedRoute from "./components/login/ProtectedRoute.tsx";
import {AuthProvider} from "./components/auth/AuthContext.tsx";
import RegisterComponent from "./components/register/RegisterComponent.tsx";
import {ToastContainer} from "react-toastify";

function App() {
    let startTheme;
    const themeLocalStorage = localStorage.getItem("theme");
    if (themeLocalStorage) {
        startTheme = themeLocalStorage
    } else {
        startTheme = THEME_DARK;
    }
    const [theme, setTheme] = useState(startTheme);

    return (
        <ThemeContext.Provider value={{theme}}>
            <div className="App" data-theme={theme}>
                <ToastContainer
                    theme={theme}
                    autoClose={1000}
                    position="top-right"
                    hideProgressBar={false}
                    closeOnClick={true}
                    pauseOnHover={true}
                    draggable={true}/>
                <ThemeComponent theme={theme} setTheme={setTheme}/>
                <Routes>
                    <Route path="/" element={
                        <AuthProvider>
                            <ProtectedRoute>
                                <CredComponent/>
                            </ProtectedRoute>
                        </AuthProvider>
                    }/>
                    <Route path="/login" element={
                        <AuthProvider>
                            <LoginComponent/>
                        </AuthProvider>
                    }/>
                    <Route path="/register" element={<RegisterComponent/>}/>
                    <Route path="*" element={<NoPage />} />
                </Routes>
            </div>
        </ThemeContext.Provider>
    );
}

export default App;