import {MainPageButtonComponent} from "./auth/MainPageButtonComponent.tsx";

const NoPage = () => {
    return (
        <>
            <MainPageButtonComponent/>
            <div className="noPage">

                <label>Страница не найдена</label>
                <h1>404</h1>
            </div>
        </>
    )
};

export default NoPage;
