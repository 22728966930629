interface KeyInputComponentProps {
    setKey: (newKey: string) => void,
    secretKey: string
}

export default function KeyInputComponent({setKey, secretKey}: KeyInputComponentProps) {
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newKey = event.target.value;
        setKey(newKey);
        localStorage.setItem("key", newKey);
    };

    return (
        <div className="keyInputComponent">
            <input type="password"
                   value={secretKey}
                   onChange={handleInputChange}
                   placeholder="Введите ключ"/>
        </div>
    );
}