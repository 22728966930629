export class Cred {
    id: string;
    username: string;
    password: string;
    siteName: string;

    constructor(id: string, siteName: string, username: string, password: string) {
        this.id = id;
        this.siteName = siteName;
        this.username = username;
        this.password = password;
    }

    static createCred(siteName: string, username: string, password: string) {
        return new Cred('', siteName, username, password);
    }

    toString() {
        return this.id + ' ' + this.siteName + ' ' + this.username + ' ' + this.password
    }

}



export function mapCred(cred: { id: string; username: string; password: string; site_name: string }): Cred {
    return new Cred(cred.id, cred.site_name, cred.username, cred.password);
}

export function fromCred(cred: Cred, key: string): { id: string; username: string; password: string; site_name: string, key: string } {
    return {
        id: cred.id,
        username: cred.username,
        password: cred.password,
        site_name: cred.siteName,
        key: key
    }
}