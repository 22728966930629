import React, {createContext, useContext, useEffect, useState} from 'react';
import {authRequest, checkAuth, logoutRequest, MAIN_PAGE_URL} from "../request/RequestSender.ts";
import {useLocation} from "react-use";

export const AuthContext = createContext({
    isAuthenticated: false,
    isLoading: false,
    auth: (login: string, password: string): Promise<void | Error> => {},
    logout: (): Promise<void | Error> => {}
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated) return;
        if (location.pathname === MAIN_PAGE_URL) {
            setLoading(true);
            checkAuth()
                .then(isAuth => {
                    setIsAuthenticated(isAuth);
                    setLoading(false);
                })
        }
    }, [isAuthenticated, location]);


    const auth = async (login: string, password: string): Promise<void | Error> => {
        setLoading(true)
        return await authRequest(login, password)
            .then(response => {
                if (!response.ok || response.status === 403) {
                    return Promise.reject(new Error('Failed to authenticate'))
                }
                setIsAuthenticated(true);
            })
            .then(
                () => {
                    setLoading(false)
                    window.location.href = MAIN_PAGE_URL;
                }
            );
    }

    const logout = async () => {
        return await logoutRequest()
            .then(response => {
                if (!response.ok || response.status === 403) {
                    return Promise.reject(new Error('Failed to logout'))
                }
                setIsAuthenticated(false);
            })
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, isLoading, auth, logout }}>
            {children}
        </AuthContext.Provider>
    );
};


export const useAuth = () => useContext(AuthContext);