import {createContext} from 'react';
import { THEME_DARK } from './ThemeConst.ts';



interface ThemeContextProps {
    theme: string;
}

const ThemeContext = createContext<ThemeContextProps>({ theme: THEME_DARK });

export { ThemeContext };