import {MAIN_PAGE_URL} from "../request/RequestSender.ts";
import './MainPageButtonComponent.css'

export function MainPageButtonComponent() {

    return(
        <a href={MAIN_PAGE_URL} className="mainPageButtonComponent">
            <button className="main-page-button"><label>Вернуться на главную</label></button>
        </a>
    )
}