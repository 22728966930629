import AddCredComponent from "./AddCredComponent.tsx";
import KeyInputComponent from "./KeyInputComponent.tsx";
import CredFilter from "./CredFilter.tsx";
import CredList from "./CredList.tsx";
import {useEffect, useState} from "react";
import {addCred, editCred, getAllCreds, LOGIN_URL, removeCred} from "../request/RequestSender.ts";
import {Cred} from "./Cred.ts";
import './CredComponent.css'
import {LogoutComponent} from "../login/LogoutComponent.tsx";

export default function CredComponent() {
    const [creds, setCreds] = useState<Cred[]>([]);
    const [secretKey, setSecretKey] = useState("");

    useEffect(() => {
        let savedKey = localStorage.getItem("key");
        if (savedKey) {
            setSecretKey(savedKey);
        } else {
            savedKey = "";
        }
        getAllCreds(savedKey)
            .then(response => setCreds(response.creds))
            .catch(reason => console.log(reason));

    }, []);

    const handleAddCred = (cred: Cred) => {
        addCred(cred, secretKey).then((newCred: Cred) => {
            setCreds([...creds, newCred]);
        })
    };

    const handleDeleteCred = (index: string) => {
        removeCred(index).then((response: Response) => {
            if (response.ok) {
                setCreds(creds.filter((cred) => cred.id !== index));
            } else if (response.status === 403) {
                window.location.href = LOGIN_URL;
            } else {
                console.error('Error deleting credential:{} with id: {}', response.statusText, index);
            }
        });
    };

    const  handleEditCred = (editedCred: Cred) => {
        editCred(editedCred, secretKey).then((editedCred: Cred) => {
            const index = creds.findIndex((cred) => cred.id == editedCred.id);
            creds[index] = editedCred;
            setCreds([...creds]);
        })
    };

    const handleFilterCred = async (siteName: string) => {
        getAllCreds(secretKey, siteName)
            .then(response => setCreds(response.creds))
            .catch(reason => console.log(reason));
    }

    return (
        <>
            <div className="credWrapper">
                <div className="cred-header">
                    <LogoutComponent/>
                    <AddCredComponent onAddCred={handleAddCred}/>
                    <KeyInputComponent setKey={setSecretKey} secretKey={secretKey}/>
                </div>
                <div className="cred-main">
                    <CredFilter filterCred={handleFilterCred}/>
                    <CredList creds={creds} onDeleteCred={handleDeleteCred} onEditCred={handleEditCred}/>
                </div>
            </div>

        </>
    );
}