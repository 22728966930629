import {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'
import App from './App.tsx'
import 'bootstrap/dist/css/bootstrap.css'
import './index.css';
import {BrowserRouter} from "react-router-dom";

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <BrowserRouter future={{v7_relativeSplatPath: true, v7_startTransition: true}}>
            <App />
        </BrowserRouter>
    </StrictMode>,
)