import React, {useState} from 'react';
import {Cred} from "./Cred.ts";

interface AddCredComponentProps {
    onAddCred: (cred: Cred) => void;
}

const AddCredComponent: React.FC<AddCredComponentProps> = ({ onAddCred }) => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [siteName, setSiteName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({
        siteName: '',
        username: '',
        password: '',
    });

    const handleOpenModal = () => {
        setIsLoginModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsLoginModalOpen(false);
        setErrors({ siteName: '', username: '', password: '' });
    };


    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const newErrors = validateForm(siteName, username, password);
        if (Object.values(newErrors).some((error) => error !== '')) {
            setErrors(newErrors);
        } else {
            onAddCred(Cred.createCred(siteName, username, password));
            setSiteName('');
            setUsername('');
            setPassword('');
            handleCloseModal();
        }
    };

    const validateForm = (siteName: string, username: string, password: string) => {
        const errors: { siteName: string; username: string; password: string } = {
            siteName: '',
            username: '',
            password: '',
        };

        if (siteName.trim() === '') {
            errors.siteName = 'Название сайта обязательно';
        }

        if (username.trim() === '') {
            errors.username = 'Логин это обязательное поле';
        }

        if (password.trim() === '') {
            errors.password = 'Пароль это обязательное поле';
        }

        return errors;
    };

    return (
        <div className="addCredForm">
            <button onClick={handleOpenModal}><i className="fa-solid fa-plus"></i></button>
            {isLoginModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <form onSubmit={handleFormSubmit}>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Название сайта"
                                    value={siteName}
                                    onChange={(event) => setSiteName(event.target.value)}
                                />
                                {errors.siteName !== '' && <p style={{color: 'red'}}>{errors.siteName}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Логин"
                                    value={username}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                                {errors.username !== '' && <p style={{color: 'red'}}>{errors.username}</p>}
                            </div>
                            <div>
                                <input
                                    type="password"
                                    placeholder="Пароль"
                                    value={password}
                                    onChange={(event) => setPassword(event.target.value)}
                                />
                                {errors.password !== '' && <p style={{color: 'red'}}>{errors.password}</p>}
                            </div>
                            <button type="submit">Сохранить</button>
                            <button onClick={handleCloseModal}>Отмена</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddCredComponent;