import './RecoveryButtonComponent.css'
import {RECOVERY_URL} from "../request/RequestSender.ts";

export function RecoveryButtonComponent() {

    return(
        <a href={RECOVERY_URL} className="recoveryComponent">
            <button className="register-button"><label>Забыли пароль?</label></button>
        </a>
    )
}