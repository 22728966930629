import {LOGIN_URL} from "../request/RequestSender.ts";
import './LoginButtonComponent.css'

export function LoginButtonComponent() {

    return(
        <a href={LOGIN_URL} className="loginButtonComponent">
            <button className="login-button"><label>Войти</label></button>
        </a>
    )
}